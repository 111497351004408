.project2x{
    background: #fff;
    color: white;
    align-items: center;
    position: relative;
    overflow: hidden;
    display: block;

    

    .imgwrap{
        display: flex;
        overflow: hidden;
        position: relative;
        align-items: flex-start;
        justify-content: right;

        @media screen and (max-width: 768px) {
            top: 2em;
            justify-content: center;
            height: fit-content;
            position:relative;
        }

        @media screen and (max-width: 425px) {
            top:0;
        }

        img{
            width: 65%;
            height: auto;
            object-fit: cover;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }
    
    .title{
        top:0;
        height: fit-content;
        margin-top: 150px;
        padding: 0 30px;
        color: black;
        position: absolute;
        font-size: 30px;

        @media screen and (max-width: 425px) {
            padding: 0 15px;
            position: relative;
            padding-top: 10px;
            text-align: center;
        }

        .title2{
            font-size: 10px;

        }

        @media screen and (max-width: 1090px) {
            font-size: 15px;
            margin-top: 20px;
        }
    }
    .container{
        bottom: 5em;
        position: absolute;

        @media screen and (max-width: 1090px) {
            bottom: 0em;
        }

        @media screen and (max-width: 768px) {
            top:0.5em;
            position:relative;
        }

        @media screen and (max-width: 425px) {
            top:0em;
            position:relative;
        }
    
    .left{

        color: black;
        height: 100%;
        overflow: hidden;
        display: block;
        padding: 25px;
        

        .box{
            width:50%;

            @media screen and (max-width: 1090px) {
                width: 70%;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                box-shadow: none;
            }

            flex-direction: column;
            border: 2px solid black;

            .box2{
                text-align: justify;
                background-color: #fff;
                padding: 10px 10px;
                opacity: 0.8;

                @media screen and (max-width: 768px) {
                    opacity: none;
                }
                h1{
                    font-size: 20px;

                    @media screen and (max-width: 1090px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }

}
}