.app{
    height: 100vh;

    .sections{
        height: 100vh;
        position: relative;

        scroll-behavior: smooth;    

        @media screen and (min-width: 768px) {
        
        scrollbar-width: none;
        
        &::-webkit-scrollbar{
            display:none;
        }

        > *{
            width: 100vw;
            height: 100vh;
        }
        }
        
 
        .section1{
            width: 100%;
            height: 100vh;
            background-color: white;
            position: relative;
            top: 0;
        }

        .section2{
            height: fit-content;
        }

        .fitContent{
            @media screen and (max-width: 768px){
                height: fit-content;
            }
        }

        .fitContentex{
            @media screen and (max-width: 768px){
                height: fit-content;
            }
        }
        .fitContent1{
            height: fit-content;
        }

    }
}