@import "../global.scss";

.landing{
    background-color: white;

    .videoContainer{
        background-color: black;
        width: 100vw;
        height: 100%;
        position: absolute;
        display: flex;

        overflow: hidden;



        video{
            height: auto;
            width: 100vw;
            object-fit: cover;
        }
    }

    .container{
        width: 100vw;
        height: calc(100vh);
        position: relative;
        display: flex;

        @include mobile{
            flex-direction: column-reverse;
            align-items: center;
        }
        .icon2{
            position: absolute;
            top:20em;
            padding-left: 50px;
            z-index: 2;
            display: flex;
            flex-direction: column;
            -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

            @include mobile{
                display: none;
            }

            .linkedin{
                margin: 5px 0px;
                color: #fff;
                 &:hover{
                        color: #0077B5;
                    }
            
                
                @include mobile{
                    margin-right: 15px;
                }
            }
            .researchgate{
                margin: 5px 0px;
            }
            .git{
                margin: 5px 0px;
            }
        }
        .left{
            flex: 0.5;
            position: relative;
            float: right;
    
            @include mobile{
                margin-right: auto;
                flex: 1;
            }
    
            .wrapper{
                width: 100%;
                height: 100%;
                padding-left: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: white;
    
                @include mobile{
                    padding-left: 10px;
                    width: 100vw;
                }

                .contact{
                    padding-bottom: 15px;
                    @media screen and (min-width: 768px) {
                        display: none;
                }
                .linkedin2{
                    padding-right: 20px;
                }
                }
    
                h1{
                    font-size: 60px;
                    margin: 10px 0;
                    font-weight: 400;
    
                    @include mobile{
                        font-size: 30px;
                    }
    
                }
                h2{
                    padding-top: 500px;
                    font-size: 35px;
                    font-weight: 400;
                        
                    @include mobile{
                        padding-top: 0px;
                        font-size: 15px;
                    }
                }
                h3{
                    font-size: 30px;
                    font-weight: 400;
                    
                    span{
                        font-size: 40px;
                        color: rgb(253, 193, 159);
                        font-weight: 400;
    
                        @include mobile{
                            font-size: 15px;
                        }
                    }
                    .ityped-cursor{
                        animation: blink 1s infinite;
                    }
                    @keyframes blink {
                        100%{
                            opacity:0;
                        }
                        
                    }
    
                    @include mobile{
                        font-size: 20px;
                    }
    
                }
                .button{
                    display: flex;
                    align-items: center;
                    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
                    .icon1{
                        font-size: 100px;
                        padding-top: 50px;
                        color: white;
                        animation: Blicker 3s infinite;
    
                        @include mobile{
                            padding-top: 10px;
                            padding-bottom: 15px;
                            font-size: 50px;
                        }
                    }
                    @keyframes Blicker{
                        50%{
                            opacity: 1;
                        }
                        100%{
                            opacity: 0;
                        }
                    }
    
                    
                }
                
    
            }
        }
    
        .right{
            flex: 0.5;
            overflow: hidden;
    
            @include mobile{
                flex: 2;
            }
    
            .imgContainer{
                height:90%;
                width: auto;
                background-color: transparent;
                display: flex;
                align-items: flex-end;
                justify-content: center;
    
                img{
                    height: 70%;
                    border-radius: 20%;
                    border: 3px solid #bbb;
                    margin-bottom: 100px;

                    @include mobile{
                        margin-bottom: 0;
                    }
                    
                }
            }
        }

    }

}