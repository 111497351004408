.footer{
    background-color: #111011;
    color: white;
    display: flex;
    flex-direction: column; 
    
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
        .head{
            padding: 10px;
        }
        .top{
            display: flex;
            padding: 10px ;
            

            .linkedin{
                padding-right: 3em;
                @media screen and (max-width: 375px) {
                    padding: 0em 0em;
                }
            }

            .Facebook{
                padding-right: 3em;
                @media screen and (max-width: 375px) {
                    padding: 0em 1.5em;
                }
            }

            .git{

                @media screen and (max-width: 375px) {
                    padding: 0em 0em;
                }
            }
        }

        .bottom{
            padding: 20px;
            text-align: center;
            h3{
                font-size: 15px;
                @media screen and (max-width: 425px) {
                    font-size: 13px;
                }
            }

        }
    }
}