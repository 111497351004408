.education{
    background-color: black;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column; 
    

        

    .imgWrap{

        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;

        img{
            margin-top: 240px;
            width: 100%;
        }
    }

    .title{
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 150px ;
        overflow: hidden;

        @media screen and (max-width: 425px) {
            padding-top: 100px ;
        }

        h1{
            padding: 5px 10px;
            font-size: 50px;
            font-weight: 500;
            border: 2px solid white;
            border-radius: 10px;

            @media screen and (max-width: 768px) {
                font-size: 30px;;
            }
            @media screen and (max-width: 425px) {
                font-size: 23px;;
            }
        }
    }


    .contentContainer{
        padding-top: 50px;
        width: 100%;
        position: relative;
        display: flex;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            padding-top: 20px;
        }

        .left{
            flex: 0.5;
            padding: 0 50px;
            color: white;
            text-align: right;
            
            @media screen and (max-width: 425px) {
                text-align: left;
                padding: 0 30px;
            }
            
            h1{
                padding: 20px 0;
                @media screen and (max-width: 425px) {
                    font-size: 22px;;
                }
            }
            

            p{
                
            }

            .grade1{
                padding: 15px 50px;
                padding-bottom: 20px;
                color: rgb(255, 158, 100) ;
                

                @media screen and (max-width: 425px) {
                    padding: 15px 5px;
                }

            }

            .grade2{
                padding: 15px 50px;
                padding-bottom: 10px;
                color: rgb(255, 158, 100) ;

                @media screen and (max-width: 425px) {
                    padding: 15px 5px;
                }

            }

            .honors{
                display: flex;
                flex-direction: column;

                p{
                    padding-bottom: 10px;
                }
                p2{
                    padding-bottom: 5px;
                }
                p1{
                    padding-left: 20px;
                    @media screen and (max-width: 425px) {
                        padding-left: 10px;
                    }
                }
            }
        }
    
        .right{
            flex: 0.5;
            height: 100%;
            padding: 0 50px;
            padding-bottom: 25px;

            @media screen and (max-width: 425px) {
                text-align: left;
                padding-left: 30px;
                padding-right: 0px;
            }
            
            h1{
                
                padding: 20px 00px;

                @media screen and (max-width: 425px) {
                    font-size: 22px;;
                }
            }

            p{  
                text-align: justify;
                padding-bottom: 20px;
                padding-right: 30px;
            }
            p1{
                color: rgb(255, 158, 100);
            }
            
        }
    }

}