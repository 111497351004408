.skills{
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding-bottom: 80px;
    

    h1{
        margin-top: 90px;
        font-size: 50px;
        color: white;
        

        @media screen and (max-width: 425px) {
            font-size: 30px;
        }

    }
    h2{
        font-size: 30px;
        color: white;
        padding: 5px;
        border-radius: 10px;
        text-decoration: underline;
        @media screen and (max-width: 425px) {
            font-size: 22px;
        }
    }
    .container1{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .item{
            width: 200px;
            height: 150px;
            border-radius: 20px;
            margin: 10px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 425px) {
                margin: 10px 20px;
                width: 100px;
            }

            .imgContainer{
                background-color: white;
                width: 150px;
                height: 100px;
                border-radius: 20px;
                border: 1px solid rg(240,239,239);
                margin: 10px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 425px) {
                    width: 100px;
                }

                img{
                    width: 70%;
                }
            }

            h4{
                color: white;
                font-size: 20px;

                @media screen and (max-width: 425px) {
                    font-size: 15px;
                    text-align: center;}
            }
            
        }
    }

    .t2{
        padding-top: 50px;
        text-decoration: underline;
        
        h3{
            font-size: 30px;
            color: white;

            @media screen and (max-width: 425px) {
                font-size: 22px;
            }
        }
    }
    .container2{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        overflow: hidden;

        @media screen and (max-width: 425px) {
            width: 100%;
        }

        .item{
            width: 200px;
            height: 150px;
            border-radius: 20px;
            margin: 10px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            @media screen and (max-width: 425px) {
                margin: 10px 20px;
                width: 100px;
            }
            

            .imgContainer{
                background-color: white;
                width: 150px;
                height: 100px;
                border-radius: 20px;
                border: 1px solid rg(240,239,239);
                margin: 10px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 425px) {
                    width: 100px;
                }

                img{
                    width: 60%;
                }
            }
            .caption{
                height: fit-content;
                overflow: hidden;
            h4{
                color: white;
                font-size: 20px;
                @media screen and (max-width: 425px) {
                    font-size: 15px;
                    text-align: center;
                    
                }}
            }
            
        }
    }
}