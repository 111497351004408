.project4{
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column; 

    .title{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 150px ;
        font-weight: bold;

        @media screen and (max-width: 768px) {
            padding-top: 20px ;
            font-size: 22px;
        }

        h1{
            padding: 5px 10px;
            font-size: 50px;
            font-weight: 500;
            border: 2px solid black;
            border-radius: 10px;

            @media screen and (max-width: 768px) {
                font-size: 22px;
            }
        }
    }


    .contentContainer{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        position: relative;
        display: flex;
        

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        .left{
            flex: 0.4;
            padding: 0 50px;
            height: 500px;
            overflow: hidden;
            
            .imgWrap{
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                overflow: hidden;
                width: 100%;
                height: 100%;
        
                img{
                    width: 100%;
                }
            }

            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    
        .right{
            flex: 0.6;
            height: auto;
            padding: 50px 50px;

            @media screen and (max-width: 768px) {
                flex: none;
                height: fit-content;
                padding: 0px 50px;
            }

            @media screen and (max-width: 375px) {
                flex: none;
                height: fit-content;
                padding: 0px 40px;
            }

            p{
                padding-bottom: 15px;
                padding-right: 30px;
                text-align: justify;

                @media screen and (max-width: 768px) {
                    padding-bottom: 15px;
                    padding-right: 0em;
                }
                           
            }

            p1{
                color: rgb(255, 158, 100);
            }
            
        }
    }

}