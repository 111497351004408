.sidemenu{
    width: 300px;
    height: 100vh;
    background-color: black;
    position:fixed;
    top: -300px;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.8;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    transition: all 1s ease;

    &.active{
        top: 0;
        right: 0;
    }

    ul {
        margin: 0;
        padding: 50px;
        list-style: 0;
        font-size: 30px;
        font-weight: 200;
        color: white;
    }

    li{
        margin-bottom: 30px;
        a {
            font-size: inherit;
            color: inherit;
            text-decoration: none ;
        }

        &:hover{
            font-weight: 500;
            border-top: 2px solid rgb(255, 158, 100);
        }
    }

}