@import "../../components/global.scss";

.navbar{
    
    color: white;
    width: 100%;
    height: 80px;
    position: fixed; 
    opacity: 0.6;
    top: 0;
    z-index:3;
    transition: all 1s ease;
    overflow: hidden;

    .test{
        background-color: rgb(16, 16, 14);
        color: white;
        width: 100%;
        height: 80px;
        position: fixed; 
        top: 0;
        z-index:-1;
        transition: all 1s ease;
        overflow: hidden;
    }

    .wrapper{
        padding: 5px 30px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left{
            display: flex;
            align-items: center;
            justify-content: center;

            .imgWrap{
                cursor: pointer;
                align-items: center;
                -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

                img{
                    width: auto;
                    height: 7vh;

                    @media screen and (max-width: 768px) {
                        height: 8vh;
                    }
                }
            }
            .logo{
                -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
                font-size: 30px;
                font-weight: 700;
                text-decoration: none;
                color: white;
                padding-left: 10px;

                @media screen and (max-width: 768px) {
                    display: none;
                }
                
            }

        }

        .right{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .menuItem{
                    display: flex;
                    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
                .menuLink{
                    font-size: 20px;
                    color: white;
                    text-decoration: none;
                    margin-right: 60px;

                    @media screen and (max-width: 1129px) {
                        display: none;
                    }

                    &:hover{
                        border-top: 2px solid rgb(255, 158, 100);
                    }
                }
            }
            
            .menu{
                display: none;

                @media screen and (max-width: 1129px) {
                        width: 32px;
                        height: 25px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        cursor: pointer;
                    }

                span{
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;
                    transform-origin: left;

                    transition: all 1s ease;
                }

                
            }
        }
    }

    &.active{
        background-color: black;
        opacity: 0.7;
        color: $mainColor;

        .menu{
            -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
            span{
                &:first-child{
                    background-color: $mainColor;
                    transform: translateX(50%);
                }
                &:nth-child(2){
                    background-color: $mainColor;
                    transform: translateX(20%);
                }
                &:last-child{
                    background-color: $mainColor;
                }
            }
        }
    }
}